import { Button } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { useSubmit, useLocation } from '@remix-run/react';
import posthog from 'posthog-js';

// import { useCookieConsent } from '~/content/globalStore/cookieConsent';
import { trackEvent } from '~/utils/plausible';

const ThemeModeToggler = (): JSX.Element => {
	const submit = useSubmit();
	let location = useLocation();
	const theme = useTheme();
	//@ts-ignore
	const { themeToggler } = theme;
	const { mode } = theme.palette;

	return (
		<Button
			variant={'outlined'}
			// id='themeModeTogglerButton'
			onClick={(e) => {
				e.preventDefault();
				themeToggler();
				//? submit form to server
				let formData = new FormData();
				formData.append('themeMode', mode === 'light' ? 'dark' : 'light');
				submit(formData, {
					method: 'post',
					// action: '/',
					action: location.pathname,
					encType: 'multipart/form-data',
					// encType: 'application/x-www-form-urlencoded',
					// relative: 'path',
					// replace: true,
					// preventScrollReset: true,
				});

				trackEvent('theme-mode', {
					// callback: () => console.log('ThemeModeToggler clicked'), //? remove after testing
					props: {
						// time: new Date().toLocaleString(),
						variation: mode === 'light' ? 'dark' : 'light',
					},
				});
				// posthog.capture('my event', { property: 'value' })
				posthog.capture('theme-mode', {
					variation: mode === 'light' ? 'dark' : 'light',
				});
			}}
			aria-label='Dark mode toggler'
			color={mode === 'light' ? 'primary' : 'secondary'}
			sx={{
				borderRadius: 0,
				minWidth: 'auto',
				padding: 0.5,
				borderColor: alpha(theme.palette.divider, 0.2),
			}}
		>
			{mode === 'light' ? (
				<svg
					width={20}
					height={20}
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 24 24'
					stroke='currentColor'
				>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth={2}
						d='M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z'
					/>
				</svg>
			) : (
				<svg
					width={20}
					height={20}
					xmlns='http://www.w3.org/2000/svg'
					fill='none'
					viewBox='0 0 24 24'
					stroke='currentColor'
				>
					<path
						strokeLinecap='round'
						strokeLinejoin='round'
						strokeWidth={2}
						d='M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z'
					/>
				</svg>
			)}
		</Button>
	);
};

export { ThemeModeToggler };
